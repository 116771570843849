// retorna staging ou production de acordo com a URL
import { env } from "$env/dynamic/public";

export const getEnv = () => {
  return env.PUBLIC_ENV ?? "development";
};

export const produtoIdEnum = {
  cadastro: 11,
  orion: 14,
  pai: 2,
  trade: 15,
};

export const produtoNomeEnum = {
  cadastro: "Cadastro",
  orion: "Orion",
  pai: "PAI",
  trade: "Trade",
};

export const getEndpoint = () => {
  const environment = getEnv();

  // development
  let endpoint = "https://orion-api-dev-j4hfprrh5q-rj.a.run.app";

  if (environment === "staging") {
    endpoint = "https://orion-api-stg-j4hfprrh5q-rj.a.run.app";
  } else if (environment === "production") {
    endpoint = "https://orion-api-j4hfprrh5q-rj.a.run.app";
  }

  return env.PUBLIC_CADASTRO_ENDPOINT ?? endpoint;
};

export const getRedirectMap = () => {
  const environment = getEnv();

  // development
  let redirectMap = {
    orion: "https://hml.orion.febrafar.com.br/sso",
    pai: "https://hml.pai.febrafar.com.br/sso",
    trade: "https://hml.trade.febrafar.com.br/sso",
    cadastro: "https://cadastro-v2-dev.web.app/sso",
  };

  if (environment === "staging") {
    redirectMap = {
      orion: "https://hml.orion.febrafar.com.br/sso",
      pai: "https://hml.pai.febrafar.com.br/sso",
      trade: "https://hml.trade.febrafar.com.br/sso",
      cadastro: "https://hml.cadastro.febrafar.com.br/sso",
    };
  } else if (environment === "production") {
    redirectMap = {
      orion: "https://orion.febrafar.com.br/sso",
      pai: "https://pai.febrafar.com.br/sso",
      trade: "https://trade.febrafar.com.br/sso",
      cadastro: "https://cadastro.febrafar.com.br/sso",
    };
  }

  return {
    orion: env.PUBLIC_ORION_REDIRECT ?? redirectMap.orion,
    pai: env.PUBLIC_PAI_REDIRECT ?? redirectMap.pai,
    trade: env.PUBLIC_TRADE_REDIRECT ?? redirectMap.trade,
    cadastro: env.PUBLIC_CADASTRO_REDIRECT ?? redirectMap.cadastro,
  };
};

export const getPosthogKey = () => {
  const environment = getEnv();

  // staging que também é usado pra development
  let posthogKey = "phc_Vx24u3QPu4GstYHIlJOCLbRZUOYgYhOWzxVrOrJfufz";

  if (environment === "production") {
    posthogKey = "phc_JAuE5yOujdYf6l6BQvc6CaUsVveEIe1EjkzUNkB7sXQ";
  }

  return env.PUBLIC_POSTHOG_API_KEY ?? posthogKey;
}
